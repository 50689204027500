$COL1: #ffffff; //
$COLFLAT1: #ffffff; //
$COL2: #111111; //
$COLFLAT2: #111111; //
$COL3: rgba(17,17,17,0.6); //
$COLFLAT3: #3d3d3d; //
$COL4: #ffffff; //
$COLFLAT4: #ffffff; //
$COL5: #111111; //
$COLFLAT5: #111111; //
$COL6: #111111; //
$COLFLAT6: #111111; //
$COL7: #ffffff; //
$COLFLAT7: #ffffff; //
$COL8: #111111; //
$COLFLAT8: #111111; //
$COL9: #111111; //
$COLFLAT9: #111111; //
$COL10: #111111; //
$COLFLAT10: #111111; //
$COL11: #111111; //
$COLFLAT11: #111111; //
$COL12: #111111; //
$COLFLAT12: #111111; //
$COL13: #111111; //
$COLFLAT13: #111111; //
$COL14: #111111; //
$COLFLAT14: #111111; //
$COL15: rgba(17,17,17,0.251); //
$COLFLAT15: #646464; //
$COL16: rgba(17,17,17,0.502); //
$COLFLAT16: #484848; //
$COL17: rgba(17,17,17,0.749); //
$COLFLAT17: #2d2d2d; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #ffffff; //
$COLFLAT19: #ffffff; //
$COL20: #ffffff; //
$COLFLAT20: #ffffff; //
$COL21: #ffd700; //
$COLFLAT21: #ffd700; //
$COL22: #ffffff; //
$COLFLAT22: #ffffff; //
$COL23: #ffd700; //Yellow
$COLFLAT23: #ffd700; //Yellow
$COL24: rgba(255,215,0,0.741); //Yellow transparent
$COLFLAT24: #dec021; //Yellow transparent
$COL25: #ccc8c8; //
$COLFLAT25: #ccc8c8; //
$COL26: #eeecec; //Light Grey Theme
$COLFLAT26: #eeecec; //Light Grey Theme
$COL27: rgba(0,0,0,0); //Button Transparent
$COLFLAT27: #808080; //Button Transparent
$COL28: #ad0000; //RED
$COLFLAT28: #ad0000; //RED
$COL29: #000000; //faq bolder
$COLFLAT29: #000000; //faq bolder
$COL30: rgba(254,254,254,0.129); //Trans white
$COLFLAT30: #909090; //Trans white
$COL31: rgba(255,255,255,0.82); //White Transparent
$COLFLAT31: #e8e8e8; //White Transparent
$COL32: rgba(0,0,0,0); //
$COLFLAT32: #808080; //
$COL33: rgba(39,201,229,0.839); //White Transparent
$COLFLAT33: #35bdd5; //White Transparent
$COL34: rgba(255,255,255,0.459); //White Transparent buttom
$COLFLAT34: #bababa; //White Transparent buttom
$COL35: rgba(13,178,127,0.839); //Green Transparent
$COLFLAT35: #20aa7f; //Green Transparent
$COL36: rgba(229,39,98,0.839); //White Transparent
$COLFLAT36: #d53567; //White Transparent
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
