body { background-color: $COL1; }
body { color: $COL2; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL8;
font-family: 'Raleway';
font-weight: 300;
font-style: normal;
line-height: 1;
font-size: 40px;

@media #{$medium-up} {
font-size: 50px;

}
@media #{$large-up} {
font-size: 60px;

}
}
h2 {
color: $COL9;
font-family: 'Raleway';
font-weight: 300;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 30px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL10;
font-family: 'Raleway';
font-weight: 300;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL11;
font-family: 'Raleway';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

}
h5 {
color: $COL12;
font-family: 'Raleway';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

}
h6 {
color: $COL13;
font-family: 'Raleway';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

}
.button {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 32px;

@media #{$large-up} {
font-size: 38px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL14;
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Raleway';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: $COL3}
/* Beta Panel:43 */
.MES43 {
background-color: $COL31;
color: $COL6;
padding: 15px;

 }
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: $COL6;
 }
a.MEC43 { color: $COL1;
&:hover { color: $COL25; }
 }
cite.MEC43{
color: $COL6;
}
/* :32 */
.MES32 {
padding: 20px 10px;

@media #{$medium-up} {
padding: 30px;

}
 }
/* Free form:33 */
.me-block.me-Form.MES33,  body.MES33 {background-color:transparent;
  
 input[type='submit']{border-style: solid;background-color: $COL21;
&:hover {background-color: $COL24}
border-width: 0;
color: $COL6;
@media #{$medium-up} { font-size: 110%; };
@media #{$large-up} { font-size: 120%; };
padding: 15px 50px;

border-radius: 5px;
}
@include placeholder($COL1);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL25;
background-color: $COL30;
color: $COL1;
}
::-webkit-input-placeholder { color: $COL1; opacity: 0.5;}
:-moz-placeholder {color: $COL1; opacity: 0.5;} 
::-moz-placeholder {color: $COL1; opacity: 0.5;}
:-ms-input-placeholder {color: $COL1; opacity: 0.5;}

  }
/* Primary:34 */
.MES34 {
background-color: $COL21;
 }
/* Main Menu:35 */
nav.me-Menu.MES35 {
& .menu-item.MEC35, & .menu-item.MEC35 > div.MEC35{ & > a.MEC35{color: $COL6;
}
 &:hover > a.MEC35{color: $COL17;
}
 }
&.horizontal > .menu-item.MEC35 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC35 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC35 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC35 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Product Landing Page:36 */
.MES36 {
background-color: $COL6;
& > .underlay, & > .inner-overlay { opacity: 0.65 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/3790/1032');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL7;
min-height:350px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
 }
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: $COL7;
 }
cite.MEC36{
color: $COL7;
}
/* Round:37 */
.MES37 {
border-radius: 50%;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Book Appointment form:38 */
.me-block.me-Form.MES38,  body.MES38 {background-color:transparent;
  
 input[type='submit']{border-style: solid;background-color: $COL23;
&:hover {background-color: $COL24}
border-width: 0;
color: $COL6;
@media #{$medium-up} { font-size: 110%; };
@media #{$large-up} { font-size: 120%; };
padding: 10px 30px;

@media #{$medium-up} {
padding: 10px;

}
@media #{$large-up} {
padding: 15px 30px;

}
border-radius: 5px 20px;
}
@include placeholder($COL6);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL25;
background-color: $COL1;
color: $COL6;
}
::-webkit-input-placeholder { color: $COL6; opacity: 0.5;}
:-moz-placeholder {color: $COL6; opacity: 0.5;} 
::-moz-placeholder {color: $COL6; opacity: 0.5;}
:-ms-input-placeholder {color: $COL6; opacity: 0.5;}

  }
/* 2017 Home page:39 */
.MES39 {
background-color: $COL25;
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/2669/758');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL6;
@media #{$large-up} {
min-height: 700px;};
padding: 15px 0;

 }
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: $COL6;
 }
cite.MEC39{
color: $COL6;
}
/* Border:40 */
.MES40 {
border-radius: 40px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 3px;
border-style: solid;
border-color: $COL16;
 }
/* Light Panel - Dark Text:41 */
.MES41 {
background-color: $COL31;
color: $COL6;
border-radius: 5px 30px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

 }
h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: $COL6;
 }
a.MEC41 { color: $COL1;
&:hover { color: $COL25; }
 }
cite.MEC41{
color: $COL6;
}
/* Header white:42 */
.MES42 {
background-color: $COL31;
color: $COL6;
 }
h1.MEC42, h2.MEC42, h3.MEC42, h4.MEC42, h5.MEC42, h6.MEC42 { color: $COL6;
 }
a.MEC42 { color: $COL6;
&:hover { color: $COL25; }
 }
cite.MEC42{
color: $COL6;
}
/* Light Panel -Blue:44 */
.MES44 {
background-color: $COL33;
color: $COL6;
border-radius: 5px 30px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

 }
h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: $COL1;
 }
a.MEC44 { color: $COL1;
&:hover { color: $COL25; }
 }
cite.MEC44{
color: $COL6;
}
/* News block:30 */
.MES30 {
background-color: $COL1;
font-size: 14.4px;
@media #{$large-up} {
font-size: 18px;
};
padding: 0 0 15px 0;

border-width: 1px;
border-style: solid;
border-color: $COL26;
 }
a.MEC30 { color: $COL16;
 }
cite.MEC30{
font-size: 14.4px;
@media #{$large-up} {
font-size: 18px;
};
}
/* Primary Button black:45 */
.MES45 {
background-color: $COL15;
&:hover { background-color: $COL16;}
color: $COL1;
font-size: 19.8px;
@media #{$medium-up} {
font-size: 21.6px;
};
@media #{$large-up} {
font-size: 23.4px;
};
border-radius: 4% 20%;
padding: 15px;

border-width: 2px;
border-style: solid;
border-color: $COL1;
 }
/* Light Panel -Green:46 */
.MES46 {
background-color: $COL35;
color: $COL6;
border-radius: 5px 30px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

 }
h1.MEC46, h2.MEC46, h3.MEC46, h4.MEC46, h5.MEC46, h6.MEC46 { color: $COL1;
 }
a.MEC46 { color: $COL1;
&:hover { color: $COL25; }
 }
cite.MEC46{
color: $COL6;
}
/* note panel:47 */
.MES47 {
 }
h1.MEC47, h2.MEC47, h3.MEC47, h4.MEC47, h5.MEC47, h6.MEC47 { color: $COL28;
 }
/* cart button:48 */
.MES48 {
background-color: $COL6;
color: $COL7;
padding: 8px 12px;

 }
/* Popup Forms:49 */
.me-block.me-Form.MES49,  body.MES49 {background-color:transparent;
  
 input[type='submit']{border-style: solid;background-color: $COL23;
&:hover {background-color: $COL24}
border-width: 0;
border-radius: 3px 10px;
}
@include placeholder($COL6);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
color: $COL6;
}
::-webkit-input-placeholder { color: $COL16;}
:-moz-placeholder {color: $COL16;} 
::-moz-placeholder {color: $COL16;}
:-ms-input-placeholder {color: $COL16;}

  }
/* Light Panel -Raspberry:50 */
.MES50 {
background-color: $COL36;
color: $COL6;
border-radius: 5px 30px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

 }
h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: $COL1;
 }
a.MEC50 { color: $COL1;
&:hover { color: $COL25; }
 }
cite.MEC50{
color: $COL6;
}
/* Green Button:51 */
.MES51 {
background-color: $COL35;
&:hover { background-color: $COL16;}
color: $COL1;
font-size: 14.4px;
border-radius: 5px 20px;
padding: 8px 12px;

 }
/* View Site:52 */
.MES52 {
background-color: $COL32;
color: $COL6;
font-size: 14.4px;
border-radius: 5px 20px;
padding: 8px 12px;

border-width: 2px;
border-style: solid;
border-color: $COL6;
&:hover { border-color: $COL16; }
 }
/* Grey Button:53 */
.MES53 {
background-color: $COL16;
&:hover { background-color: $COL15;}
color: $COL1;
font-size: 14.4px;
border-radius: 5px 20px;
padding: 8px 12px;

 }
/* Border with Hover:54 */
.MES54 {
&:hover, &.hover { background-color: $COL24;}
border-radius: 10px 40px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 3px;
border-style: solid;
border-color: $COL16;
 }
/* 2017 Pages:55 */
.MES55 {
background-color: $COL25;
& > .underlay, & > .inner-overlay { opacity: 0.7 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/2669/758');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL6;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
padding: 15px 0;

 }
h1.MEC55, h2.MEC55, h3.MEC55, h4.MEC55, h5.MEC55, h6.MEC55 { color: $COL6;
 }
cite.MEC55{
color: $COL6;
}
/* Arrows:31 */
.MES31 {
& .slider-arrow {color: $COL1;
font-size: 30px;
@media #{$medium-up} {
font-size: 40px;
};
@media #{$large-up} {
font-size: 40px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: $COL26;
border-radius: 50%;
border-style:solid;
border-width:4px;
@media #{$medium-up} {
border-width: 4px;
};
@media #{$large-up} {
border-width: 4px;};
&:hover{border-color: $COL25;
;}
background-color: $COL23;
background-clip: padding-box;
&:hover {background-color: $COL24;}
width:20px;
height:20px;
@media #{$medium-up} {
width:20px;
height:20px;
};
@media #{$large-up} {
width:20px;
height:20px;
};
&:hover{background-color: $COL24;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL25;
background-color: $COL24;
  }
}
 }
/* :29 */
details.MES29 {
color: $COL6;
font-size: 18px;
& > article { color: $COL6;
font-size: 18px;
 }
& > summary{padding: 15px;}

& > summary {& > div  > i{color: $COL6;
font-size: 16px;
}}
& > article a { color: $COL6;
&:hover { color: $COL6; }
 }
& > article li { color: $COL6;
 }
 }
/* Light:2 */
.MES2 {
background-color: $COL4;
color: $COL5;
 }
/* Primary:14 */
.MES14 {
background-color: $COL21;
padding: 20px 15px;

 }
/* Light:3 */
.MES3 {
background-color: $COL4;
color: $COL5;
 }
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL5;
 }
cite.MEC3{
color: $COL5;
}
/* Dark:4 */
.MES4 {
background-color: $COL6;
color: $COL7;
 }
/* Dark:5 */
.MES5 {
background-color: $COL6;
color: $COL7;
 }
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL7;
 }
a.MEC5 { color: $COL25;
&:hover { color: $COL1; }
 }
cite.MEC5{
color: $COL7;
}
/* Shade 1:6 */
.MES6 {
background-color: $COL15;
color: $COL18;
 }
/* Shade 1:7 */
.MES7 {
background-color: $COL15;
color: $COL18;
 }
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL18;
 }
a.MEC7 { color: $COL1;
&:hover { color: $COL25; }
 }
cite.MEC7{
color: $COL18;
}
/* Shade 2:8 */
.MES8 {
background-color: $COL16;
color: $COL19;
 }
/* Shade 2:9 */
.MES9 {
background-color: $COL16;
 }
/* Shade 2:10 */
.MES10 {
background-color: $COL16;
color: $COL19;
 }
h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: $COL19;
 }
a.MEC10 { color: $COL1;
&:hover { color: $COL26; }
 }
cite.MEC10{
color: $COL19;
}
/* Shade 3:11 */
.MES11 {
background-color: $COL17;
color: $COL20;
 }
/* Shade 3:12 */
.MES12 {
background-color: $COL17;
color: $COL20;
 }
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: $COL20;
 }
cite.MEC12{
color: $COL20;
}
/* Primary:13 */
.MES13 {
background-color: $COL24;
&:hover { background-color: $COL23;}
color: $COL6;
&:hover { color: $COL6;}
font-size: 23.4px;
 }
/* Primary:15 */
.MES15 {
background-color: $COL21;
color: $COL22;
 }
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL22;
 }
cite.MEC15{
color: $COL22;
}
/* :28 */
.MES28 {
 }
/* Home:16 */
.MES16 {
background-color: $COL6;
color: $COL7;
 }
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: $COL7;
 }
cite.MEC16{
color: $COL7;
}
/* Contact:17 */
.MES17 {
background-color: $COL6;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/1988/39');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL1;
 }
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: $COL21;
 }
cite.MEC17{
color: $COL1;
}
/* Features:18 */
.MES18 {
background-color: $COL6;
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/85/40');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL1;
font-size: 19.8px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 16.2px;
};
 }
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: $COL21;
 }
cite.MEC18{
color: $COL1;
font-size: 19.8px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 16.2px;
};
}
/* Footer:19 */
.MES19 {
background-color: $COL6;
color: $COL7;
font-size: 14.4px;
padding: 20px 10px;

@media #{$large-up} {
padding: 30px 10px;

}
 }
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL7;
 }
a.MEC19 { color: $COL25;
&:hover { color: $COL1; }
 }
cite.MEC19{
color: $COL7;
font-size: 14.4px;
}
/* How it works:20 */
.MES20 {
background-color: $COL6;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://udo.net.au/img/85/40');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
/* Primary Button:21 */
.MES21 {
background-color: $COL21;
&:hover { background-color: $COL24;}
color: $COL6;
font-size: 19.8px;
@media #{$medium-up} {
font-size: 21.6px;
};
@media #{$large-up} {
font-size: 23.4px;
};
border-radius: 4% 20%;
padding: 15px;

 }
/* Theme background:22 */
.MES22 {
background-color: $COL26;
font-size: 18px;
padding: 0 0 15px 0;

 }
a.MEC22 { color: $COL16;
 }
cite.MEC22{
font-size: 18px;
}
/* Pricing Primary:23 */
.MES23 {
background-color: $COL21;
color: $COL6;
border-width: 8px;
border-style: solid;
border-color: $COL26;
 }
h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: $COL6;
 }
cite.MEC23{
color: $COL6;
}
/* Pricing Grey:24 */
.MES24 {
background-color: $COL26;
color: $COL6;
border-width: 8px;
border-style: solid;
border-color: $COL26;
 }
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: $COL6;
 }
cite.MEC24{
color: $COL6;
}
/* Checkout Button:25 */
.MES25 {
background-color: $COL27;
color: $COL1;
font-size: 21.6px;
padding: 8px;

@media #{$medium-up} {
padding: 15px 16px;

}
border-width: 2px;
border-style: solid;
border-color: $COL1;
&:hover { border-color: $COL23 $COL21 $COL23 $COL23; }
 }
/* Delete Button:26 */
.MES26 {
color: $COL28;
&:hover { color: $COL17;}
font-size: 23.4px;
 }
/* Cart Button:27 */
.MES27 {
border-width: 2px;
border-style: solid;
border-color: $COL1;
&:hover { border-color: $COL21 $COL23 $COL23 $COL23; }
 }
/* Section Button:56 */
.MES56 {
background-color: $COL32;
color: $COL6;
&:hover { color: $COL23;}
font-size: 19.8px;
padding: 5px;

 }
